/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta, MapInfo} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-is-extremely-common",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-is-extremely-common",
    "aria-label": "hearing loss is extremely common permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing loss is extremely common."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In fact, it affects 1 in 8 Americans aged 12 and older."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re experiencing some of the common symptoms of ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), " listed below, you’re not alone. More than 30 million people in the United States are struggling to hear clearly every day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The good news is, hearing loss and quality of life can be drastically improved with ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "modern hearing aids"), " that are now as small as coffee beans. The key is to get quality care as soon as you can so you can preserve the hearing you have left, stay healthy, and continue to live life to the fullest."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signs-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signs-of-hearing-loss",
    "aria-label": "signs of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signs of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss symptoms often can be explained away at first. You may think it’s not that bad or blame it on something else, such as a crowded environment or just not paying attention."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The signs can also be so subtle that you may not recognize that you’re starting to lose some hearing ability. You may think people are mumbling or speaking too softly, or perhaps you may think there is too much background noise. But as time goes on, you will notice that your hearing only continues to worsen no matter where you are and what you’re doing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you notice these issues becoming more frequent, or others are mentioning that you may have hearing loss, it’s a good idea to get a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " with a professional. Here are some more common symptoms that can help you determine if it’s time to have your hearing checked."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "You keep turning up the TV or radio – and others are complaining")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Do you constantly find yourself reaching for the remote to turn up the volume because you can’t understand what is being said? Do you frequently hear from others, “Man, that is loud” or “Can you turn that down?” Hearing loss is more about not understanding speech, rather than the volume. So, if you’re constantly turning up the volume, it won’t really help you better understand what is being said."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "You often ask others to repeat themselves")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the main signs of hearing loss is always asking others to repeat what they said. People with hearing loss often struggle with higher-pitched sounds. If you can’t hear the higher-pitched consonants (S, F, Th, Sh, Ch, K, P, and H) that help us distinguish words from each other, it will be difficult to understand what someone is saying. Experiencing this all day, everyday, in all of your conversations or when listening may indicate hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Background noise, especially in crowded environments, seems to cause problems in all your conversations")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s no doubt that some environments make it hard to hear. But when you have hearing loss, every environment becomes a problem. If you start noticing that you’re consistently struggling to hear, consider that hearing loss may be to blame."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "You often feel exhausted from struggling to hear and understand people all day")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you have hearing loss, your brain has to work harder to understand speech and sounds. When you're struggling to hear all day, it can be mentally and physically exhausting. You may\nfind yourself feeling fatigued and maybe even sleeping more throughout the day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "You avoid social situations, especially in more challenging environments")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s proven that people with hearing loss tend to avoid social situations and hobbies they once enjoyed. You may feel like you can’t participate fully in conversations or worry about embarrassment or responding inappropriately to something you heard incorrectly. Or, perhaps it’s a safety issue, such as with cycling on the road. Not being social can lead to isolation and depression, which are serious health risks."), "\n", React.createElement(LandingPageCta, {
    copy: "Try hearing aids for Hearing Loss",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "frequently-asked-questions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#frequently-asked-questions",
    "aria-label": "frequently asked questions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Frequently asked questions"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How can I recognize hearing problems?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re experiencing any of the above symptoms, it’s good to keep a journal that documents what’s happening. This can help you determine if it’s an ongoing problem, how often it’s occurring, and in what situations. You can also ask your friends and family if they’ve noticed anything in their interactions with you. And of course, having a hearing test with a hearing specialist can help you determine if there’s cause for concern."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "What are the main hearing loss causes?")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noise-induced hearing loss – Can occur from a one-time exposure but more often happens overtime. Things like loud concerts, machinery such as lawn mowers, listening to ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/headphones/",
    className: "c-md-a"
  }, "headphones"), " too loud, and even traffic and construction noise can impact your hearing."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Age - Hearing loss can simply come on as you grow older due to changes to the inner ear. Sometimes it’s genetic, too. Check to see if anyone in your family has had trouble hearing."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Illnesses and injuries - Ear infections and viruses, as well as trauma to the head and ear, can damage your inner ear."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is not a complete list, so please talk to your doctor with any concerns."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Can hearing loss be prevented?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Age-related hearing loss is hard to prevent once it starts. You can reduce the chance of hearing loss overtime by wearing ear protection around loud noises, keeping headphones at a lower volume, and avoiding louder environments. Having regular checkups with your doctor to monitor your hearing health can help catch issues early. Wearing hearing aids can also help you protect the hearing you have left if you currently have hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "When should I contact a doctor?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think you have hearing loss, the key is to act right away. Treating your hearing loss sooner than later can help you protect the hearing you have left. It will also quickly improve your quality of life and your relationships with loved ones."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "What should I do if I think I have hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The first thing to do is find a quality hearing care company and program that tailors treatments to your individual needs. Hearing loss is not one-size-fits all, so you want to make sure your hearing aids are programmed to your personal hearing needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-can-hearcom-help-me",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-can-hearcom-help-me",
    "aria-label": "how can hearcom help me permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How can hear.com help me?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " offers a free, supportive consultation with a care specialist to first determine your needs and which ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " may best fit your lifestyle and preferences. You’ll be referred to one of our top hearing professionals who is nearby and often in your own neighborhood. They will conduct a painless, thorough hearing exam so you’ll know your level of hearing loss and the best way to treat it. They will then be able to program your hearing aids to your individual needs. You may even qualify for a virtual appointment where you don’t even need to leave your home."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-assistance-from-an-experienced-audiologist-near-your-location",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-assistance-from-an-experienced-audiologist-near-your-location",
    "aria-label": "get assistance from an experienced audiologist near your location permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get assistance from an experienced audiologist near your location"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You shouldn’t have to go far for quality hearing care. That’s why we’ve partnered with 2,000 top specialists and audiologists across the US – some in your own neighborhood – who can deliver convenient care and support near your home. Pairing high-quality hearing professionals with best-in-class hearing aids ensures you’ll have the best hearing experience and results for the long term."), "\n", React.createElement(MapInfo));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
